.salesorder .rpv-default-layout__body {
	padding-top: 0;
}

.salesorder .rpv-default-layout__toolbar {
	background: rgba(0, 0, 0, 0.5);
	border: none;
	top: unset;
	bottom: 0;
}

.salesorder .rpv-default-layout__toolbar:hover {
	background: rgba(0, 0, 0, 1);
}

.salesorder .rpv-core__minimal-button {
	margin-left: 40px;
}

.salesorder .rpv-core__minimal-button::after {
	content: ' Click to Preview';
	margin-top: 2px;
	margin-left: 5px;
}

.salesorder .rpv-default-layout__body {
	background: #f5f6f8;
	padding: 16px;
	border-radius: 4px;
}

.salesorder .rpv-default-layout__container {
	border: none;
}

/* #f5f6f8 */
