#loader {
	width: calc(100% - 210px);
	height: 90%;
	position: fixed;
	z-index: 2000;
	bottom: 0;
	right: 0;
	svg {
		width: 250px !important;
		height: 250px !important;
		display: block;
		margin: auto;
	}
}

.show {
	display: block;
}

.hide {
	display: none;
}
