$primary: #007fff;
$darkprimary: #153e60;
$darkprimaryhover: #365a7f;

.ant-layout {
	min-height: 100vh;
}

.ant-btn-primary {
	background: $darkprimary;
	border-color: $darkprimary;
}

.ant-btn:hover,
.ant-btn:focus {
	color: #fff;
	border-color: $darkprimaryhover;
	background: $darkprimaryhover;
}

.ant-layout-content {
	min-height: 100vh;
}

// Form dropdown list design
.ant-select-item-option {
	font-size: 15px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
}

.ant-form-item-explain {
	font-size: 10px;
}

.salesman__modal {
	.ant-modal-header {
		background-color: #f6fdff;
	}
	width: 50%;
}

.collapse__card__wrapper {
	height: calc(100vh - 400px);
	overflow: auto;
	padding-bottom: 0;
}

.collapse__card {
	background-color: transparent;
	border: none;
}

.collapse__card .ant-collapse-item-active {
	h1 {
		color: #fff;
	}

	.ant-collapse-header p {
		color: #fff;
	}
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
	padding-right: 16px;
}

.cascader-field {
	.ant-form-item {
		width: 100%;
	}
}

.ant-checkbox-group {
	&.vertical-checkbox {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.custom__option.ant-select-item {
	background-color: #fff;
}
.custom__option.ant-select-item-option-active:hover {
	background-color: #fff;
}

.ant-picker-input input {
	border: none !important;
}

.report__drpdwn {
	width: 400px;
	margin-top: 0.7rem;
}

.form__field {
	width: 250px !important;
}

.form__switch {
	margin-top: 14px;
}

.ant-table-wrapper {
	width: 100%;
}

input::placeholder,
.ant-select-selection-placeholder {
	font-size: 12px !important;
}

.uploadspage-antd {
	&__textname {
		min-height: 70px;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.ant-upload-select {
		width: 100%;
	}
	.ant-upload-list-text-container {
		padding: 10px 0;
	}
}

.ant-menu-submenu {
	.ant-menu-sub {
		background-color: #352a35;
		a {
			color: #fff;
			font-size: 12px;
		}
		.ant-menu-item:hover,
		.ant-menu-item-selected {
			background-color: #352a35;
			width: 99.99%;
		}
	}
}

.ant-menu-submenu-placement-rightTop::before {
	left: 0;
}

.RTCDetailsPage {
	.ant-collapse-header {
		font-size: 14px;
		line-height: 19px;
		padding: 24px 30px !important;
		position: relative;
	}

	.ant-collapse-content-box {
		padding: 0;
	}

	.ant-select-selection-item {
		font-size: 12px !important;
		line-height: 13px !important;
		font-size: 12px !important;
	}

	.anticon.anticon-right.ant-collapse-arrow {
		right: 40px;
		width: 40px;
		height: 40px;
		top: 50%;
		font-size: 22px;
		transform: translateY(-50%);
		position: absolute;
		display: flex;
		color: #023047;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	.ant-collapse-content {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			width: 96%;
			top: 0;
			border: 1px solid #e1e1e1;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.RTCDetailsPage {
	.stepOrangeIcons {
		.ant-steps-item-icon {
			border-color: #ca5010;
		}
		span.ant-steps-icon {
			color: #ca5010;
		}
	}
	.stepIcons {
		.ant-steps-item-icon {
			background: transparent !important;
			border-color: #000;
			.ant-steps-icon {
				color: #000 !important;
			}
		}
	}
	.ant-steps-item-tail {
		&::after {
			background: #000 !important;
		}
	}
}

.wmsLogsPage {
	.ant-checkbox-disabled {
		.ant-checkbox-inner {
			background-color: #ffffff;
			border-color: #ffffff !important;
		}
	}
}

.RTCDetailsPageNoBorder {
	.ant-collapse-content-box {
		padding: 0 30px;
	}
	.ant-collapse-content {
		&:before {
			border: none;
		}
	}
}

.settleCash {
	.ant-modal-close-x {
		color: #fdfcfc;
	}
	.ant-modal-body {
		padding: 0;
	}
	.ant-table-footer {
		background: #fff;
		text-align: right;
		padding-right: 50px;
	}
}

.customDropDown {
	.ant-select-selector {
		background: #faf9f8;
	}
}

.ant-input-number-out-of-range input {
	border: 1px solid #ff4d4f;
}

.attachmentModal {
	.ant-modal-body {
		padding: 0;
	}
	.ant-carousel .slick-prev,
	.ant-carousel .slick-next {
		width: 60px;
		height: 60px;
		background: #f3f1f1 !important;
		display: flex;
		border-radius: 50%;
		transform: translateY(-50%);
		color: #000 !important;
		font-size: 12px;
		line-height: 14px;
		&::before {
			content: none;
		}
	}
	.ant-carousel .slick-prev {
		left: 30px;
	}
	.ant-carousel .slick-next {
		right: 30px;
	}
	.ant-modal-close {
		color: #fff;
	}
}

.nextDayPage {
	.ant-collapse-item {
		.ant-collapse-expand-icon {
			align-self: center;
			margin-right: 10px;
		}
		.ant-collapse-header .ant-collapse-arrow {
			width: 20px;
			height: 20px;
			margin-right: 5px;
			border-radius: 50%;
			background: #ca5010;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
		}
	}
	.ant-spin.ant-spin-spinning.ant-spin-dot {
		width: 100%;
		left: 0 !important;
	}

	.ant-spin-text {
		font-size: 20px;
		padding-top: 20px;
	}
}

.nextDayModalPopup {
	.ant-modal-body {
		padding: 0;
	}
	td.ant-table-cell {
		padding: 10px;
	}
}

.uploadChallen {
	.ant-upload-select-text {
		width: 100%;
	}
	.ant-upload-list-item-card-actions {
		display: none;
	}
}

// for banksettlement style collapse
.bankSettlement {
	.ant-collapse-header {
		padding: 24px !important;
		color: rgb(0, 0, 0);
		font-size: 16px;
		line-height: 18px;
	}
	.anticon.anticon-right.ant-collapse-arrow {
		right: 40px;
		width: 40px;
		height: 40px;
		background: #f6f5f5;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}
	.ant-tabs-nav-wrap {
		padding: 0 8px !important;
	}

	.ant-picker {
		border-color: #000;
		border-radius: 4px;
		min-height: 35px;
		.ant-picker-focused {
			outline: none;
			box-shadow: none;
			border-color: transparent;
		}
		input {
			&:focus,
			&:hover {
				outline: none;
				box-shadow: none;
				border-color: transparent;
			}
			&::placeholder {
				color: #000;
				font-size: 12px;
			}
		}
	}
	.ant-picker-suffix {
		color: #000;
	}
}

tfoot {
	td {
		font-weight: 700;
	}
}
.ant-upload-list {
	max-width: 15rem;
}

.ant-table-wrapper {
	overflow: auto;
	background: #ffffff;
}
