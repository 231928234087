@import '~antd/dist/antd.css';
@import 'ant-design-override';
@import 'common';
@import 'components/loader';
@import 'components/table-shimmer';

.invoice-handover .ant-table-selection {
	display: none;
}
.errorHeader .ant-modal-header {
	background-color: #f0f0f0;
	padding-right: 32px;
}
.inputINdex input[type='number']::-webkit-inner-spin-button,
.inputINdex input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
.uploadChallen .ant-upload-text-icon {
	display: none;
}
.bankcheque-settlement .ant-table-thead .ant-table-selection {
	display: none;
}

.show-pdc-error {
	input {
		font-size: 8.2px !important;
		color: red !important;
	}
}
