/* width */
// ::-webkit-scrollbar {
// 	width: 2px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
// 	background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// 	background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// 	background: #555;
// }

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.p-10 {
	padding: 10px;
}

.pt-10 {
	padding-top: 10px;
}

.px-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mx-15 {
	padding-left: 15px;
	padding-right: 15px;
}

.my-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.mt-15 {
	margin-top: 15px;
}

.w-100 {
	width: 100%;
}

.rotate {
	transform: rotate(180deg);
}

.rotate,
.reset-rotate {
	transition-duration: 0.4s;
	transition-property: transform;
}

.customDownloadMsg {
	padding: 0;
	float: right;
	color: #fff;
	min-height: 61px;
	line-height: 45px;
	margin: -8px 5px 0 0;
	.ant-message-notice-content {
		background: #143e60;
	}
	.ant-message-loading {
		.anticon {
			color: #fff;
		}
	}
}

.bblr-4 {
	border-bottom-left-radius: 4px;
}

.btlr-4 {
	border-top-left-radius: 4px;
}

.disabled-div {
	pointer-events: none;
	opacity: 0.6;
}

.wrap {
	word-wrap: break-word;
}

.site-collapse-custom-collapse {
	.site-collapse-custom-panel {
		margin-bottom: 24px;
		overflow: hidden;
		background: #f7f7f7;
		border: 0px;
		border-radius: 2px;
	}
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding: 10px;
	vertical-align: middle;
}

.ant-table-thead > tr > th {
	font-weight: bold;
}

.ant-table-pagination.ant-pagination {
	margin: 0;
	padding: 16px;
	background: #fff;
}

.ant-collapse-item {
	background: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-input {
	min-height: 37px;
	height: auto;
	align-items: center;
	border: 1px solid #d9d9d9 !important;
}

.ant-select-selection-item {
	word-break: break-all;
	white-space: normal;
}

.customDropdown .ant-select-selector {
	padding-top: 0 !important;
}

.ant-picker {
	padding: 0 11px;
}

.ant-form-item-control-input-content input {
	min-height: 37px;
	height: unset !important;
}

.ant-select-selector input {
	min-height: 35px !important;
}

.ant-input-affix-wrapper {
	padding: 0 11px;
	border: 1px solid #d9d9d9 !important;
}

.ant-input-affix-wrapper input {
	border: none !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
	border-color: #ff4d4f !important;
}
