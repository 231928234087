.upload-modal {
	.ant-modal-content {
		.ant-modal-body {
			.ant-upload {
				background-color: #e4ebe4 !important;
				border: 1px dashed #88c788;
			}
		}
	}
}

.upload-modal-epod {
	.ant-modal-content {
		.ant-modal-close-x {
			width: 28px;
			height: 28px;
			line-height: 28px;
		}
		.ant-modal-body {
			padding: 24px;
			background: #ffffff;
			border-radius: 2px;
			.ant-upload {
				background-color: #e4ebe4 !important;
				border: 1px dashed #88c788;
			}
		}
	}
}

.upload-modal-epod .ant-modal-body {
	padding: 0;
}
