.table-shimmer {
	.even-row {
		opacity: 0.5;
	}
	.tableWrapper {
		width: 100%;
		display: table;
		border-spacing: 0;
		border-collapse: collapse;
	}

	.table-head {
		font-size: 0.75rem;
		color: #545454;
		text-align: left;
		word-break: keep-all;
		word-wrap: break-word;
		padding: 0.75rem 0.5rem;
		font-weight: bold;
	}

	.table-cell {
		font-weight: bold;
		display: table-cell;
		padding: 8px 8px 8px 8px;
		line-height: 1.2857142857142856rem;
	}

	.table-head {
		background: #fff;
		border-bottom: 1px solid #c8ced3;
	}

	.table-cell div {
		display: flex;
		background: linear-gradient(
			90deg,
			rgba(190, 190, 190, 0.2) 25%,
			rgba(129, 129, 129, 0.24) 37%,
			rgba(190, 190, 190, 0.2) 63%
		);
		background-size: 400% 100%;
		animation: ant-skeleton-loading 1.4s ease infinite;
	}
}
